import { useContext } from 'react';
import LoadingContext from './LoadingContext';
import classNames from 'classnames/bind';
import styles from './Loading.module.scss';

const css = classNames.bind(styles);
const Loading = () => {
    const { showLoading } = useContext(LoadingContext);

    return <>{showLoading && <div className={css('loading__indicator')} />}</>;
};

export default Loading;
