import { createContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [showLoading, setShowLoading] = useState(false);

    const showLoadingIndicator = (isLoading) => {
        setShowLoading(isLoading);
    };

    return <LoadingContext.Provider value={{ showLoadingIndicator, showLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingContext;
